<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
  >
    <path
      d="M24 2.29994L22.2919 12.9008H6.97092L4.54838 3.33295L4.08427 1.5712H0V0H5.26235L5.86823 2.29994H24ZM11.1155 14.1229C10.0679 14.1229 9.21876 14.9908 9.21876 16.0614C9.21876 17.1321 10.0679 18 11.1155 18C12.163 18 13.0122 17.1321 13.0122 16.0614C13.0122 14.9908 12.163 14.1229 11.1155 14.1229ZM17.7509 14.1229C16.7033 14.1229 15.8542 14.9908 15.8542 16.0614C15.8542 17.1321 16.7033 18 17.7509 18C18.7984 18 19.6476 17.1321 19.6476 16.0614C19.6476 14.9908 18.7984 14.1229 17.7509 14.1229Z"
      fill="currentColor"
    />
  </svg>
</template>
